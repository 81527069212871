.sections--block {
  padding: 0 var(--mobile--box-padding);
  margin-top: 2em;

  @media only screen and (min-width: $bp-small) {
    margin-top: 3em;
  }

  @media only screen and (min-width: $bp-medium) {
    padding: 0 15% 0 calc(15% - 3em - 31px);
  }
}

.section {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 3em;

  @media screen and (min-width: $bp-small) {
    margin-bottom: 5em;
  }

  &#partners {
    margin-bottom: 4em;

    @media screen and (min-width: $bp-small) {
      margin-bottom: 6em;
    }
  }

  &.alt-background {
    position: relative;

    &::before {
      background-color: var(--alternative-background-color);
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      margin: -1rem -100% -2.6rem;

      @media screen and (min-width: $bp-small) {
        margin-block: -2rem -3.1rem;
      }

      @media screen and (min-width: $bp-medium) {
        margin-top: -3.2rem;
      }

      @media screen and (min-width: $bp-large) {
        margin-top: -3rem;
      }
    }

    &:last-of-type::before {
      margin-bottom: -8rem;
    }
  }
}

.section--main-content {
  width: 100%;

  @media screen and (min-width: $bp-medium) {
    margin-left: 3em;

    &.main-content--no-vertical {
      margin-left: calc(3em + var(--small-header-line-height));
    }
  }
}

.companies--wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 2.5em;
  gap: 2rem;

  @media screen and (min-width: $bp-large) {
    flex-direction: row;
  }

  p {
    font-size: var(--small-text-size);
  }

  .company--logo-wrapper {
    height: 77px;
    margin-bottom: 1em;

    img {
      max-height: 100%;
      width: auto;
    }
  }

  .section--company {
    &:not(:first-child) {
      margin-top: 2em;
    }

    @media screen and (min-width: $bp-large) {
      &:not(:first-child) {
        margin-top: 0;
      }
      &:nth-child(2) {
        margin-left: 2em;
      }
      &:last-child {
        //margin-top: 2em;
      }
      width: calc(50% - 2em);
    }

    @media screen and (min-width: 90em) {
      width: calc(50% - 2em);//width: calc(33% - 2em);
      &:not(:first-child) {
        margin-left: 2em;
      }
      &:last-child {
        margin-top: 0;
      }
    }

    img {
      max-width: 100%;
    }
  }
}

.section--columns {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bp-large) {
    flex-direction: row;
  }

  p {
    width: 100%;

    @media screen and (min-width: $bp-large) {
      width: 50%;
      &:not(:first-child) {
        margin-left: 2em;
      }
    }
  }

  &.contacts--columns {
    flex-direction: column;
    margin-top: 1.265625em;

    @media screen and (min-width: $bp-large) {
      flex-direction: row;
    }

    & input,
    & textarea {

background-color: var(--alternative-background-color)    }
  }
}

.partners--section {
  margin-top: 2.5em;
}

.partners--group {
  &:not(:last-child) {
    margin-bottom: 3em;
  }
}

.partners--logos {
  display: grid;
  max-width: 75em;
  align-items: center;
  margin: 1.5em auto 0;
  filter: grayscale(100%);
  grid-template-columns: 1fr 1fr;
  gap: 2em;

  @media screen and (min-width: $bp-large) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .partner--logo {
    max-width: min(200px, 100%);
    max-height: 70px;
    object-fit: contain;
    width: 100%;

    &.logo--invert {
      filter: grayscale(100%) invert(1);
    }
  }

  .partner--logo-big {
    max-width: 200px;
    max-height: 100px;
    object-fit: contain;
    width: 100%;
  }
}

.treedom--iframe-wrapper {
  margin-bottom: 3em;

  @media screen and (min-width: $bp-small) {
    margin-bottom: 5em;
  }

  .treedom--iframe {
    width: 100%;
    left: 0;
    min-height: 400px;
    height: calc(56.25vw - 15vw);

    @media screen and (min-width: $bp-medium) {
      margin-left: calc(3em + 31px);
      height: calc(56.25vw - 15vw - 3em - 31px);
      width: calc(100% - 3em - 31px);
    }
  }
}

.treedom-img, .shetech-img {
  width: 100px;
}

.identity--content {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bp-large) {
    flex-direction: row;
  }

  &--text {
    flex: 1 0 70%;

    @media screen and (min-width: $bp-large) {
      padding-right: 2rem;
    }
  }

  &--image {
    height: 75vw;
    background-image: url("../images/identity.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;

    @media screen and (min-width: $bp-large) {
      height: auto;
      width: max(100%, 200px);
      margin-top: 1rem;
      background-position: left 0 top calc(45px - 5vw);
    }

    @media screen and (min-width: $bp-big) {
      background-position: left 0 top calc(-15px - 5vw);
    }
    }
}

.mission--content {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bp-large) {
    flex-direction: row-reverse;
  }

  &--text {
    flex: 1 0 70%;

    @media screen and (min-width: $bp-large) {
      padding-left: 2rem;
    }
  }

  &--image {
    height: 75vw;
    background-image: url("../images/mission.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;

    @media screen and (min-width: $bp-large) {
      height: auto;
      margin-top: 1rem;
      background-position: left calc(-250px + 8vw) top calc(-15px - 5vw);
      width: max(100%, 200px);
      padding-top: 2rem;
    }

    @media screen and (min-width: $bp-big) {
      background-position: left calc(-150px + 8vw) top calc(-15px - 5vw);
    }
  }
}
