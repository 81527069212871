$bp-small: 30em;
$bp-medium: 43.75em;
$bp-large: 60.25em;
$bp-big: 75em;

:root {
  --black-color: black;
  --green: #2d827b;
  --white-color: white;
  --gray-color: #706e6e;
  --dark-gray: rgb(3, 3, 3);
  --light-gray: lightgray;
  --copy-color: #404040;
  --alternative-background-color: #EFF5F5;

  --hero-size: 1.8em;
  --header-size: 1.5em;
  --small-header-size: 1.1em;
  --copy-size: 1em;
  --small-text-size: 1em;
  --tiny-text-size: 0.8em;

  --small-header-line-height: var(--small-header-size) * 1.5;

  --mobile--box-padding: 5%;
  --header--desktop-right-margin: 2.78em;
}

@media screen and (min-width: $bp-medium) {
  :root {
    --hero-size: 4.43em;
    --header-size: 2.25em;
    --small-header-size: 1.2em;
    --copy-size: 1.125em;
    --small-text-size: 0.9em;
  }
}
