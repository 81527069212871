.map--wrapper {
  filter: grayscale(100%);
  min-height: 245px;

  @media screen and (min-width: $bp-large) {
    width: 50%;
  }

  iframe {
    width: 100%;
  }
}

form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  @media screen and (min-width: $bp-large) {
    margin-right: 3em;
    width: 50%;
  }

  label {
    position: absolute;
    top: -3000px;
    left: -3000px;
  }

  input[type='button'] {
    @extend .copy--font;
    width: 100%;
    margin: 0.8em auto;
    padding: 6px;
    border: 1.5px solid var(--light-gray);
    background: none;
    color: var(--gray-color);

    @media screen and (min-width: $bp-large) {
      width: 10em;
      margin-left: auto;
      margin: 0.8em 0 0 auto;
    }

    &:focus {
      outline: none;
    }
  }
}

textarea {
  resize: none;
}
textarea,
input#subject {
  resize: none;
  &::placeholder {
    @extend .header--font;

    color: var(--black-color);
    font-weight: 700;
  }

  margin-bottom: 1em;
  padding: 9px 4px;
  border: none;
  border-bottom: 1px solid var(--light-gray);

  &:focus {
    outline: 1px solid var(--copy-color);
  }

  &:valid ~ input[type='button'] {
    &:focus,
    &:hover {
      outline: 1px solid var(--copy-color);
      cursor: pointer;
    }
  }
}
