footer {
  background: var(--dark-gray);
  color: var(--white-color);
  padding: 0 var(--mobile--box-padding) 0.5em;

  @media screen and (min-width: $bp-medium) {
    padding: 0em 2em;
  }

  @media screen and (min-width: $bp-large) {
    padding: 0em 7em;
  }

  .footer--contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: var(--small-text-size);
    padding: 2em 0;

    @media screen and (min-width: $bp-medium) {
      flex-direction: row;
      padding: 3em 0;
    }

    @media screen and (min-width: $bp-large) {
      padding: 6em 0;
    }

    .contacts--box {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 2em;

        @media screen and (min-width: $bp-medium) {
          margin-top: 0;
        }
      }

      .contacts--title {
        font-size: var(--small-header-size);
      }

      .follow-us--logos {
        display: flex;
      }

      .support--title {
        margin-top: 15px;
        .support--logos {
          display: flex;
          flex-direction: column;
        }
      }

      .social--link {
        display: flex;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      .social--logo {
        width: 1.875em;
      }

      .linkedin--logo {
        filter: grayscale(100%) invert(100%);
      }
      .twitter--logo {
        filter: grayscale(100%);
      }
    }
  }

  .footer--notice {
    font-size: var(--tiny-text-size);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $bp-small) {
      flex-direction: row;

      .footer--info:first-child {
        margin-right: 0.5em;
      }
    }
  }
}
