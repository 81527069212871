@keyframes slideImage {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30vw);
  }
}

header {
  --wrapper-height: 65vh;
  width: 100%;
  padding: var(--mobile--box-padding);

  @media screen and (min-width: $bp-small) {
    --wrapper-height: 56.25vw;
    padding: 3% var(--mobile--box-padding);
  }
  @media screen and (min-width: $bp-medium) {
    padding: 2.22em var(--header--desktop-right-margin) 3.1% 9.2%;
  }

  height: var(--wrapper-height);

  .fortitude--logo {
    width: clamp(100px, 17.8vw, 320px);
    margin-bottom: calc(var(--wrapper-height) * 0.13);

    @media screen and (min-width: $bp-medium) {
      margin-bottom: calc(var(--wrapper-height) * 0.158);
    }
  }
}

.sections--image-section {
  --wrapper-height: 65vh;
  padding: 8.2% var(--mobile--box-padding);

  @media screen and (min-width: $bp-small) {
    --wrapper-height: 47.36vw;
    padding: var(--mobile--box-padding);
  }

  @media screen and (min-width: $bp-medium) {
    padding: 8.2% 3.1% 3.1% 7.9%;
  }

  height: var(--wrapper-height);

  .image-section--logo-row {
    display: flex;
    align-items: center;
    margin-bottom: (calc(var(--wrapper-height) * 0.16));
  }

  .fortitude--logo {
    width: clamp(65px, 10vw, 320px);
  }

  .treedom--logo {
    width: clamp(125px, 14.7vw, 320px);
  }
}

.hero--section {
  position: relative;
  width: 100%;

  .hero-text--line {
    display: block;

    &:nth-child(2) {
      margin-left: 1ch;

      @media screen and (min-width: $bp-small) {
        margin-left: 2ch;
      }
    }
  }

  .hero-section--slide {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--wrapper-height);
    z-index: -1;

    img {
      height: 100%;
      width: auto;
    }

    .header-section--image {
      object-fit: cover;
      max-height: 1344px;

      @media screen and (min-width: $bp-small) {
        width: 100vw;
      }
    }

    .hero-section--image {
      position: relative;
      object-fit: cover;
      max-height: 100%;
      min-height: 0vw;
      animation: slideImage 10s infinite linear;
      will-change: transform;

      @media screen and (min-width: $bp-small) {
        width: 130vw;
      }
    }
  }
}
