.nav--wrapper {
  display: flex;
  margin-bottom: 3.1%;
}

.nav--toggle {
  display: flex;
  position: relative;
  height: 26px;
  margin-left: auto;
  -webkit-user-select: none;
  user-select: none;

  .nav--hamburger {
    margin-left: auto;
    stroke: var(--black-color);
    transition: 0.2s;
    z-index: 4;

    line {
      stroke-width: 3px;
    }

    g {
      &:first-child {
        transition: opacity 0s 0.2s;
        opacity: 1;

        line {
          transform: translateY(0);
          transition: transform 0.2s 0.2s;
        }
      }

      &:last-child {
        transition: opacity 0s 0.2s;
        opacity: 0;

        line {
          transform: rotate(0deg);
          transform-origin: center;
          transition: transform 0.2s;
        }
      }
    }
  }

  label {
    position: absolute;
    top: -3000px;
    left: -3000px;
  }

  input {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 5;

    &:checked {
      position: fixed;
      left: auto;
      right: var(--mobile--box-padding);

      @media screen and (min-width: $bp-medium) {
        right: var(--header--desktop-right-margin);
      }

      ~ .nav--hamburger {
        position: fixed;
        right: var(--mobile--box-padding);
        stroke: var(--black-color);

        @media screen and (min-width: $bp-medium) {
          right: var(--header--desktop-right-margin);
        }

        g {
          &:first-child {
            opacity: 0;

            line {
              transition: transform 0.2s;

              &:first-child {
                transform: translateY(7px);
              }
              &:last-child {
                transform: translateY(-7px);
              }
            }
          }

          &:last-child {
            opacity: 1;

            line {
              transition: transform 0.2s 0.2s;

              &:first-child {
                transform: rotate(45deg);
              }
              &:last-child {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
    &:checked ~ .navigation--sections {
      transform: translate(-100%, 0) scale(1, 1);
      opacity: 1;
    }
  }

  .navigation--sections {
    position: fixed;
    top: 0;
    right: max(-350px, -70vw);
    width: min(350px, 70vw);
    height: 100vh;
    margin: 0;
    padding: 125px 0 50px;
    transform-origin: 0% 0%;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    background: var(--white-color);
    -webkit-font-smoothing: antialiased;
    z-index: 3;
    list-style-type: none;

    a {
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        color: var(--green)
      }
    }

    li {
      padding: 1rem 50px;
      color: var(--black-color);
      font-size: 1.5em;
      font-weight: 700;

      @extend .header-vertical--font;
    }
  }
}
