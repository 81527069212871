.group-structure {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 60%;
  margin: 3rem auto 0;
  width: 100%;

  &--fortitude {
    grid-column: 1/5;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 8rem;
    gap: 1rem;

    & > picture {
      height: clamp(4rem, 8vw, 8rem);
    }
  }

   & .group-lines--top:nth-child(3) {
     border-right: 1px solid darkgrey;
     height: 20px;
   }

  & .group-lines--top:nth-child(4) {
    border-left: 1px solid darkgrey;
    height: 20px;
  }

  & .group-lines--bottom-left {
    border-top: 2px solid darkgrey;
    border-left: 2px solid darkgrey;
    width: 100%;
    height: 20px;
  }

  & .group-lines--bottom-right {
    border-top: 2px solid darkgrey;
    border-right: 2px solid darkgrey;
    width: 100%;
    height: 20px;
  }

  &--bitrock {
    &--logo {
      grid-column: 1/3;
      place-self: center;
      margin-right: 1rem;

      & svg {
        width: min(100%, 400px);
        height: auto;
      }
    }

    &--text {
      grid-column: 1/3;
      font-size: clamp(.6rem, 1.6vw ,1.2rem);
      line-height: clamp(0.8rem, 2.6vw ,2.2rem);
      margin-right: 1rem;
    }
  }
  &--proactivity {
    &--logo {
      grid-column: 3/5;
      place-self: center;
      margin-left: 1rem;

      & img {
        width: min(100%, 400px);
      }
    }

    &--text {
      grid-column: 3/5;
      font-size: clamp(.6rem, 1.6vw ,1.2rem);
      line-height: clamp(0.8rem, 2.6vw ,2.2rem);
      margin-left: 1rem;
    }
  }
}
