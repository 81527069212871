/* raleway-200 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local(''),
    url('../fonts/raleway/raleway-v18-latin-200.woff2') format('woff2');
}
/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
    url('../fonts/raleway/raleway-v18-latin-300.woff2') format('woff2');
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    url('../fonts/raleway/raleway-v18-latin-700.woff2') format('woff2');
}
/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local(''),
    url('../fonts/montserrat/montserrat-v15-latin-100.woff2') format('woff2');
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
    url('../fonts/montserrat/montserrat-v15-latin-700.woff2') format('woff2');
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''), url('../fonts/lato/lato-v17-latin-300.woff2') format('woff2');
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    url('../fonts/lato/lato-v17-latin-regular.woff2') format('woff2');
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''), url('../fonts/lato/lato-v17-latin-700.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

.copy--font {
  font-family: 'Lato', sans-serif;
}
.header--font {
  font-family: 'Raleway', sans-serif;
}
.header-vertical--font {
  font-family: 'Montserrat', sans-serif;
}

body,
p {
  @extend .copy--font;
  font-weight: 300;
  font-size: var(--copy-size);
  color: var(--copy-color);
  line-height: 1.75em;
}
h1,
h2 {
  @extend .header--font;
}

h1 {
  color: var(--white-color);
  line-height: 1;
  min-height: 0vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--hero-size);

  @media screen and (min-width: $bp-medium) {
    font-size: 5.5vw;
  }
  @media screen and (min-width: $bp-big) {
    font-size: min(5.5vw, var(--hero-size));
  }
}

.header--light-font {
  @extend .header--font;
  font-weight: 300;
}

.header--hero-first-line {
  display: block;
}

h2 {
  font-size: var(--header-size);
  line-height: 2.53125rem;
}

h3 {
  &.subtitle {
    @extend .copy--font;
    font-weight: 400;
    font-size: var(--small-header-size);
  }
}

aside {
  &.header--vertical {
    color: var(--gray-color);
    writing-mode: vertical-lr;
    @extend .header-vertical--font;
    font-weight: 100;
    font-size: var(--small-header-size);
    display: none;

    @media screen and (min-width: $bp-medium) {
      display: block;
    }
  }
}

.logo-row--ampersand {
  @extend .header--font;
  font-weight: 200;
  color: var(--white-color);
  font-size: clamp(2em, 5.6vw, var(--hero-size));
  margin: 0 0.4em;
}

a {
  color: var(--black);

  &.external-link {
    @extend .copy--font;
    font-weight: 400;
    color: var(--green);
  }

  &.telephone-link {
    color: var(--white);
    text-decoration: none;
  }
}

.copy--bold {
  @extend .copy--font;
  font-weight: 700;
}
